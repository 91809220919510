<template>
  <div id="main">
    <div class="login d-flex align-items-center">
      <div class="w-100">
        <h1 class="title text-center mb-5">
          The Nikon Difference
        </h1>
        <div class="main-form">
          <h5 class="my-4"></h5>
          <ValidationObserver v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(onSubmit)">
              <h5 class="my-4">
                Forgot password
              </h5>
              <ValidationProvider
                v-slot="{ errors }"
                name="Your email"
                rules="required|email"
              >
                <div class="form-group mt-4">
                  <label>Enter your email</label>
                  <input v-model="email" type="email" name="email" class="form-control" />
                  <span class="txt-error">{{ errors[0] }}</span>
                </div>
              </ValidationProvider>

              <div>
                <!-- eslint-disable-next-line vue/no-v-html -->
                <b-alert v-if="sucessMessage" show variant="success" v-html="sucessMessage"></b-alert>
                <!-- eslint-disable-next-line vue/no-v-html -->
                <b-alert v-if="errorMessage" show variant="danger" v-html="errorMessage"></b-alert>
              </div>
              <button :disabled="isSending" type="submit" class="btn btn-primary mt-3 mr-3 px-5">
                Send reset password email
              </button>
            </b-form>
          </ValidationObserver>
        </div>
        <div class="text-right">
          <router-link :to="{ path: '/login' }">
            Go to Login page
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import AuthService from "../../service/auth.service";

export default {
  name: "ForgotPasswordPage",
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      isSending: false,
      email: "",
      sucessMessage: "",
      errorMessage: "",
    };
  },
  computed: {},
  mounted() {},
  methods: {
    resetMessage() {
      this.sucessMessage = "";
      this.errorMessage = "";
    },
    onSubmit() {
      this.resetMessage();
      this.isSending = true;
      AuthService.forgetPassword(this.email)
        .then(response => {
          if(response) {
            this.sucessMessage = "An email has been sent.<br />Please check your inbox.";
          }
        }).catch(reason => {
          const data = reason.response.data;
            if (data) {
              this.errorMessage = data.message;
            } else {
              this.errorMessage = "Server error";
            }
        }).finally(() => {
          this.isSending = false;
        })
    },
    doRedirect() {
      this.$router.push("/login");
    },
  },
};
</script>
<style scoped>
#main {
  background-color: #ffffff;
}
.w-100 {
  width: 100%;
}
.login {
  margin: auto;
  width: 450px;
  height: 100vh;
}
.title {
  width: 100%;
}
.main-form {
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 0px 40px 40px;
}
body {
  background-color: #ffffff;
}
.txt-error {
  color: #F00;
}
</style>
